import isTBSize from './isTBSize';
/**
 * accordion01
 */
export default function accordion01() {
  if (isTBSize()) {
    $('.js-sp-accordion').on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      const $target = $this.next();

      if ($this.hasClass('_open')) {
        $this.removeClass('_open');
        $target.stop().slideUp('fast');
      } else {
        $this.addClass('_open').stop();
        $target.stop().slideDown('fast');
      }
    });
  }
}
