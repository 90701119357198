import setWindowHeight from "./setWindowHeight";
/**
 * Hamburger Menu
 */
export default function hamburgerMenu() {
  // JS Menu
  $( '.js-menu' ).on( 'click', function( e ) {
    setWindowHeight(); // 100vhの再取得
    e.preventDefault();
    const $body = $( 'body' );
    if ( $body.hasClass( 'is-open' ) ) { // Close
      $body.removeClass( 'is-open' );
    } else { // Open
      $body.addClass( 'is-open' ).removeClass( 'is-search-open' );

      $( '.js-menu-close' ).on( 'click', function() {
        $body.removeClass( 'is-open' );
      });
    }
  });
}
