/**
 * tabChange
 */
export default function tabChange() {
  // タブ切り替え
  const tabButton = '.js-tab-trigger';
  $(document).on('click', tabButton, function() {
    const $this = $(this);
    const thisTabWrap = $this.parents('.js-tab-group');
    const thisTabBtn = thisTabWrap.find('.js-tab-trigger');
    const thisTabContents = thisTabWrap.find('.js-tab-target');

    //current class
    const TabBtncurrentClass = '_active';
    const TabContentscurrentClass = '_active';

    //クリックされた tabのdata属性を取得
    const thisElmID = $this.data('tab');

    //js-tab-trigger current 切り替え
    thisTabBtn.removeClass(TabBtncurrentClass).filter('[data-tab="' + thisElmID + '"]').addClass(TabBtncurrentClass);
    $this.addClass(TabBtncurrentClass);

    //js-tab-target 切り替え
    thisTabContents.css('display', '').removeClass(TabContentscurrentClass);
    thisTabContents.filter('[data-tab="' + thisElmID + '"]').fadeIn().addClass(TabContentscurrentClass);
  });
}
