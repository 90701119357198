import { throttle } from 'throttle-debounce';
import isSPSize from './modules/isSPSize';
import hamburgerMenu from './modules/hamburgerMenu';
import smoothScroll from './modules/smoothScroll';
import pcDropdownMenu from './modules/pcDropdownMenu';
import scrollHint from './modules/scrollHint';
import accordion01 from './modules/accordion01';
import tabChange from './modules/tabChange';
import slickSlider from './modules/slickSlider';
import scrollAnimation from './modules/scrollAnimation';
import setWindowHeight from './modules/setWindowHeight';

// ブレークポイントで切り替わるclass
const breakpointClassSwitch = () => {
  if (isSPSize()) {
    $('body').removeClass('isPC').addClass('isSP');
  } else {
    $('body').removeClass('isSP is-open').addClass('isPC');
  }
};

// onload イベントで動く機能の中身
const windowOnloadInit = () => {
  // return;
  $('.js-mainvisual-anime').addClass('_show');
};

// スクロール時に動く機能の中身
const windowScrollInit = () => {
  // return;
  if ($(window).scrollTop() > 20) {
    $('.header').addClass('_shadow');
  } else {
    $('.header').removeClass('_shadow');
  }
};

windowScrollInit();
breakpointClassSwitch();

hamburgerMenu();
pcDropdownMenu();
scrollHint();
accordion01();
tabChange();
slickSlider();
scrollAnimation();
setWindowHeight();

// スムーススクロール実行
smoothScroll('.js-goto-pagetop', { forthTop: true });
smoothScroll('a[href^="#"]');

// スクロール時のイベント
window.addEventListener('scroll', throttle(250, function() {
  windowScrollInit();
}));

// リサイズ時に動くイベント
window.addEventListener('resize', throttle(250, function() {
  breakpointClassSwitch();
  slickSlider();
}));

// window on load イベント
window.addEventListener('load', throttle(250, function() {
  windowOnloadInit();
}));

