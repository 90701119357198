import slick from 'slick-carousel'; // eslint-disable-line no-unused-vars
import isSPSize from './isSPSize';
/**
 * slickSlider
 */
export default function slickSlider() {
  if (isSPSize()) {
    $('.js-slick').not('.slick-initialized').slick({
      dots: true,
      arrows: false,
      centerMode: true,
      centerPadding: '100px'
    });
  } else {
    $('.js-slick.slick-initialized').slick('unslick');
  }
}
