/**
 * PC Dropdown Menu
 */
export default function pcDropdownMenu() {
  // JS Menu
  let sethover;
  let outhover;
  $('.js-hover-nav').hover(
    function () {
      clearTimeout(outhover);
      sethover = setTimeout($.proxy(function () {
        const $this = $(this);
        const $siblings = $this.siblings();
        $siblings.removeClass('is-hover');
        sethover = setTimeout(function () {
          $this.addClass('is-hover');
          $('body').addClass('is-hover');
        }, 150);
      }, this), 200);
    },
    function () {
      const $this = $(this);
      clearTimeout(sethover);
      outhover = setTimeout(function () {
        $('body').removeClass('is-hover');
        $this.removeClass('is-hover');
      }, 500);
    }, 500);
}
