import ScrollHint from '../vendor/scroll-hint.min';
import isSPSize from './isSPSize';
/**
 * scroll Hint
 */
export default function scrollHint() {
  if (isSPSize()) {
    new ScrollHint('.js-scrollable', {
      i18n: {
        scrollable: 'スクロールできます'
      }
    });
  }
}
