/**
 * smoothScroll
 */
export default function smoothScroll( selector, options ) {
  const c = $.extend({
    speed: 650,
    easing: 'swing',
    adjust: 0,
    forthTop: false
  }, options);
  if ( selector ) {
    $( selector ).on( 'click.smoothScroll', function(e ) {
      e.preventDefault();
      const elmHash = $( this ).attr( 'href' );
      let targetOffset = 0;
      if ( elmHash === '#' ) {
        return;
      }
      targetOffset = ( c.forthTop ) ? 0 : $( elmHash ).offset().top - c.adjust;
      $( 'html,body' ).animate( {
        scrollTop: targetOffset
      }, c.speed, c.easing );
    });
  }
}
