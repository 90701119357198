/**
 * scrollAnimation
 */
export default function scrollAnimation() {
  const options = {
    rootMargin: '-200px 0px',
  };

  const callback = (entries) => {
    entries.forEach(
      (entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("_show");
        }
      }
    );
  };

  const observer = new IntersectionObserver(callback, options);

  const animationItems = document.querySelectorAll('.js-anime');
  animationItems.forEach(item => {
    observer.observe(item);
  });
}
